/* tslint:disable: class-name */
/*
 * AUTOGENERATED FILE - DO NOT EDIT
 */
import { APIRestClient } from '../../../APIRestClient';
import { PortalLogbookOverview } from '../../../schemas/PortalLogbookOverview';
export class QrPortalAPI extends APIRestClient {
    /**
     * Get cleaning Resource
     * Implementert hovedsakelig i *ewRestResourceQrPortalResources*::*getCleaningResource*
     */
    static getV1QrportalCleaningUsingHash(hash) {
        return this._get('/v1/qrportal/cleaning/{hash}', { hash }, null, null, PortalLogbookOverview.classOf(PortalLogbookOverview, new PortalLogbookOverview()), [], 'json');
    }
}
