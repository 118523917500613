import { Router } from '@vaadin/router';
import { QRPortalNotFoundView } from './views/QRPortalNotFoundView';
import { QRPortalResourceCleaningView } from './views/QRPortalResourceCleaningView';
export const router = new Router();
router.setRoutes([
    {
        path: '/resource/:hash/cleaning',
        component: QRPortalResourceCleaningView.is,
    },
    {
        path: '(.*)',
        component: QRPortalNotFoundView.is,
    },
]);
